import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'

Vue.use(Router)

let router =  new Router({
    routes: [
        {
            path:'/',
            name:'home',
            redirect:'/home',
            // component:HelloWorld
        },
        {
            path:'/home',
            component:HelloWorld

        },
        {
          path: '/home2',
          name: 'home2',
          component: () => import('@/components/HelloWorld2'),
        },
        {
            path: '/platform',
            name: 'platform',
            component: () => import('@/views/platform'),
            meta: { title: 'platform', icon: 'dashboard' }
          },
          {
            path: '/aboutus',
            name: 'aboutus',
            component: () => import('@/views/aboutus'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
          {
            path: '/news',
            name: 'news',
            component: () => import('@/views/news'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
          {
            path: '/notice',
            name: 'notice',
            component: () => import('@/views/Notice/notice'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
          {
            path: '/nomatch',
            name: '/nomatch',
            component: () => import('@/views/Notice/nomatch'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
          {
            path: '/matchNew',
            name: 'matchNew',
            component: () => import('@/views/Notice/matchNew'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
          {
            path: '/noticInfo',
            name: 'noticInfo',
            component: () => import('@/views/Notice/info'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
          {
            path: '/matchInfo',
            name: 'matchInfo',
            component: () => import('@/views/matchInfo'),
            meta: { title: 'aboutus', icon: 'dashboard' }
          },
    ]

})


export default router